import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

import { Link } from "gatsby";

const breadCrumbLevels = {
  Hem: "/",
  // Blogg: "/blogg",
  "Juridiskt meddelande": "se/juridiskt-meddelande"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/legal-notice"
);

const LegalNotice = props => {
  return (
    <Layout location={props.location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Juridiska meddelanden"
          description="Juridiska meddelanden om Matthias Kupperschmidts tjänster inom online marketing; detta inkluderar sökmotoroptimering, webbanalysimplementationer, rådgivning inom online marketing"
          lang="se"
          alternateLangs={alternateLangs}
        />
        <MainContent
          article={false}

        >
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

          <H as="h1">Juridiskt meddelande</H>

          <p>Matthias Kupperschmidt erbjuder olika tjänster (benämnda som "tjänster" nedan) inom online marketing; detta inkluderar sökmotoroptimering, webbanalysimplementationer, rådgivning inom online marketing samt utbildningar och workshops.</p>

          <p>Användningen av de tillhandahållna tjänsterna är endast tillåten för det avsedda ändamålet som beskrivs i dessa Allmänna Villkor och/eller beställningen. Dessutom är all annan användning eller bearbetning för andra ändamål endast tillåten under de strikta villkoren i dansk dataskyddslagstiftning, särskilt Dataskyddslagen och EU:s Allmänna dataskyddsförordning (GDPR). Missbruk eller otillåten användning är inte tillåten. Särskilt är fullständig användning av de tillhandahållna tjänsterna och innehållet för kommersiella ändamål av något slag och i någon medieform (tryckt, elektronisk, på CD, etc.) samt utläsning av kataloger, databaser etc. för ovannämnda ändamål eller för andra kommersiella ändamål inte tillåten och kommer att åtalas av leverantören enligt tillämplig lag med alla tillgängliga rättsliga medel.</p>
          <p>Tillgängligheten av individuella tjänster samt riktigheten och fullständigheten av innehållet/inmatningar/data garanteras inte.</p>
          <p>Dessa Villkor reglerar förhållandet mellan Matthias Kupperschmidt och dess kunder och avtalspartners (benämnda som "avtalspartner" nedan).</p>

          <p>Avsnitt 1 Omfattning</p>
          <p>Matthias Kupperschmidts tjänster utförs enbart på basis av dessa Allmänna Villkor. Därför gäller de också för alla framtida affärsrelationer även om de inte uttryckligen överenskommits igen.</p>
          <p>Användningen av de tjänster som erbjuds av Matthias Kupperschmidt till avtalspartnern styrs uteslutande av dessa Allmänna Villkor (GTC) samt de särskilda villkoren för respektive produkter/tjänster. För erbjudanden, tjänster, individuella projekt, kampanjer och andra tjänster, särskilt projektservice utöver detta, gäller de villkor som ska överenskommas i separata avtal.</p>
          <p>Allmänna villkor från avtalspartnern eller andra tredje parter är inte tillämpliga även om Matthias Kupperschmidt inte uttryckligen motsätter sig dem och/eller utför sina tjänster utan invändning.</p>

          <p>Avsnitt 2 Allmänt tjänsteomfång</p>
          <p>Bland annat är Matthias Kupperschmidt aktiv inom områdena sökmotoroptimering, webbanalys, inbound marketing, rådgivning inom online marketing samt utbildningar och workshops. Den detaljerade beskrivningen av den beställda tjänsten i varje enskilt fall framgår av respektive beställning.</p>
          <p>De tjänster och innehåll som erbjuds av Matthias Kupperschmidt tillhandahålls och publiceras av Matthias Kupperschmidt om inte annat anges specifikt. Allt innehåll som görs tillgängligt av tredje part är information från respektive skapare, författare eller distributör och inte information som tillhandahålls av Matthias Kupperschmidt. Matthias Kupperschmidt är därför inte ansvarig för riktigheten eller tillförlitligheten av detta innehåll.</p>
          <p>De tjänster som erbjuds av Matthias Kupperschmidt är endast en inbjudan att lämna ett förslag för att ingå ett avtal. Avtalspartnern är bunden till sin förklaring i tio arbetsdagar. Bekräftelse i textform (e-post räcker) från Matthias Kupperschmidt krävs för att ett avtal ska ingås. Ett affärsavtal kommer då till stånd på basis av tillämpliga GTC.</p>
          <p>Matthias Kupperschmidt är inte skyldig att ingå avtal. Han har rätt att vägra ingå avtal utan att ge några skäl. Efterföljande ändringar och tillägg till avtalen kräver skriftlig bekräftelse från Matthias Kupperschmidt. Separata avtal ska ingås av avtalspartnerna för ytterligare tjänster.</p>
          <p>Matthias Kupperschmidt förbehåller sig också rätten att utesluta avtalspartner vid en senare tidpunkt från användningen av tjänsterna om det finns bevis för att avtalspartnern missbrukar tjänsterna eller använder dem på ett sätt som kan sätta Matthias Kupperschmidts rykte på spel. Detta gäller särskilt om avtalspartnern bryter mot skyldigheter från avtalsförhållandet eller skadar andra avtalspartner till Matthias Kupperschmidt.</p>
          <p>I den mån en avtalspartner använder mjukvara från den respektive andra avtalspartnern som en del av användningen av tjänsterna, beviljar den respektive andra avtalspartnern honom – om inte annat uttryckligen regleras – en icke-exklusiv, icke-överförbar användningsrätt, för egen användning, som är tidsbegränsad till kontraktets löptid och, i innehåll, begränsad till användning inom ramen för tjänstens tillhandahållande. Ytterligare användningsrättigheter beviljas inte avtalspartnern.</p>
          <p>För underhållsändamål och för att utföra reparationer, har Matthias Kupperschmidt rätt att avbryta tillhandahållandet av tjänster eller delar av dessa i den utsträckning som är rimlig för avtalspartnern. I allmänhet ska Matthias Kupperschmidt sträva efter att utföra underhålls- och reparationsarbeten vid tider då den betungande påverkan är så låg som möjligt. Detta ger inte avtalspartnern rätt till ersättning eller kompensationskrav såvida inte Matthias Kupperschmidt är skyldig.</p>
          <p>Matthias Kupperschmidt har rätt att ingå liknande avtalsförhållanden med konkurrenter till avtalspartnern. Matthias Kupperschmidt ska dock sträva efter – utan att vara skyldig att göra så – att skydda de berättigade intressena hos avtalspartnern i detta avseende och undvika intressekonflikter. Matthias Kupperschmidt har rätt att tillhandahålla delvisa tjänster samt tjänster från tredje part.</p>
          <p>Deadlines och datum som begärts av avtalspartnern är endast bindande när Matthias Kupperschmidt uttryckligen har bekräftat dem skriftligen och om avtalspartnern har skapat alla nödvändiga förutsättningar för tillhandahållandet av tjänsten i tid som ligger inom hans påverkansområde.</p>
          <p>I den utsträckning och så länge som avtalspartnern inte har uppfyllt några av sina samarbetsförpliktelser som krävs för tillhandahållandet av tjänsten, även efter en skriftlig begäran från Matthias Kupperschmidt och en rimlig frist, ska Matthias Kupperschmidt befrias från att uppfylla den skyldighet som påverkas av denna underlåtenhet vid den tidsfrist som överenskommits i projektavtalet. Den överenskomna tidsfristen i beställningen/kontraktet för tillhandahållandet ska förlängas därefter.</p>

          <p>Avsnitt 3 Avtalsingående, avtalets omfattning</p>
          <p>Varje tjänst måste beställas separat av avtalspartnern (individuell beställning/serviceavtal). De avtalande parterna ska ömsesidigt tillhandahålla varandra det innehåll som krävs för att uppfylla beställningen. Om spårningskoder är integrerade i detta innehåll får de endast ändras eller raderas av den respektive andra avtalspartnern efter föregående samråd och överenskommelse.</p>
          <p>Matthias Kupperschmidt ska skapa en faktura för avtalspartnern för varje genomförd kampanj som tydligt visar all information som anges i punkt 1.</p>

          <p>Avsnitt 4 Skyldigheter hos avtalsparter</p>
          <p>Kunden åtar sig att stödja de åtgärder som vidtas av Matthias Kupperschmidt. Kunden ska tillhandahålla Matthias Kupperschmidt all information och data som krävs för att uppfylla uppdraget.</p>
          <p>Avtalsparterna åtar sig att säkerställa att innehållet som tillhandahålls av dem och som är kopplat till användningen av tjänsterna från Matthias Kupperschmidt inte bryter mot gällande lag i Danmark; detta gäller särskilt olagligt, omoraliskt eller pornografiskt innehåll eller sådant innehåll som förhärligar krig eller som kan utgöra en moralisk risk för barn och ungdomar eller negativt påverka deras välbefinnande.</p>
          <p>Avtalsparterna ska även rapportera alla händelser och omständigheter som kan vara viktiga för korrekt och snabb genomförande av avtalet. Detta gäller även händelser och omständigheter som avtalsparterna bara blir medvetna om under avtalets löptid. Projektförseningar på grund av att avtalsparterna inte uppfyller de nödvändiga samarbetsförpliktelserna eller inte uppfyller dem i tid sker på avtalsparternas bekostnad och påverkar inte Matthias Kupperschmidts ersättningsanspråk.</p>
          <p>Varje överträdelse av skyldigheten som anges i punkt 1 ger Matthias Kupperschmidt rätt att omedelbart säga upp avtalet mellan Matthias Kupperschmidt och avtalsparterna. Matthias Kupperschmidt förbehåller sig rätten till omedelbar uppsägning även om det bara finns en misstanke om att avtalsparterna sprider eller tolererar innehåll som beskrivs i punkt 1. Matthias Kupperschmidt har rätt men inte skyldighet att övervaka lagligheten av innehållet.</p>
          <p>Avtalsparterna åtar sig att strikt följa de tekniska och innehållsmässiga specifikationer som Matthias Kupperschmidt kräver. Eventuella förseningar och/eller ändringar av tjänster som tillhandahålls av Matthias Kupperschmidt till följd av bristande efterlevnad av dessa specifikationer sker på avtalsparternas bekostnad och ger inga anspråk mot Matthias Kupperschmidt.</p>
          <p>Dessutom ansvarar avtalsparterna för att all information och dokument som krävs för att uppfylla deras avtalsförpliktelser och det korrekta genomförandet av samarbetet görs tillgängliga för Matthias Kupperschmidt i tid, fullständigt och i en form som är lämplig för uppfyllande före den överenskomna genomförandet av avtalet.</p>
          <p>Om en part hindras från att tillhandahålla den avtalsenliga tjänsten och/eller innehållet på grund av omständigheter som den inte är ansvarig för, ska denna part informera den andra parten omedelbart och utan dröjsmål anpassa innehåll och/eller tjänster till omständigheterna. Om de nämnda omständigheterna varar längre än 24 timmar ska parterna komma överens om hur de ska gå vidare.</p>
          <p>Avtalsparterna har inte rätt att använda, reproducera, sprida, publicera, ändra eller på något annat sätt utnyttja Matthias Kupperschmidts varumärken och/eller logotyper, dess kunder och partners såvida Matthias Kupperschmidt inte uttryckligen har gett sitt skriftliga medgivande i förväg. Dessutom är avtalsparterna skyldiga att respektera tredje parts upphovsrätt eller varumärkesrätt, både tyska och utländska.</p>
          <p>Avtalsparterna samtycker till att deras innehåll, data och annan information vidarebefordras till tredje parter i den mån det är nödvändigt och krävs för att uppfylla avtalsförhållandet mellan parterna.</p>

          <p>Avsnitt 5 Avtalets löptid/uppsägning</p>
          <p>Kostnadsuppskattningar från Matthias Kupperschmidt är inte bindande. Matthias Kupperschmidt åtar sig att omedelbart informera avtalsparterna om eventuella avvikelser i de faktiska kostnaderna jämfört med den uppskattade siffran om avvikelsen är mer än tio procent.</p>
          <p>Avtalet mellan Matthias Kupperschmidt och avtalsparterna avslutas vid utgången av den överenskomna löptiden. I övrigt kan respektive avtalstid ses i erbjudandet.</p>
          <p>Om en minsta löptid har avtalats med avtalsparterna, förlängs avtalet automatiskt med den överenskomna löptiden om inte förlängningen motsätts senast 30 dagar före utgången av den relevanta avtalstiden.</p>
          <p>Avtal som har ingåtts på obestämd tid kan sägas upp av båda parter skriftligen när som helst med en uppsägningstid på tre månader till slutet av månaden.</p>
          <p>Rätten att säga upp avtalet av goda skäl förblir oförändrad. En god orsak föreligger särskilt om en av parterna bryter mot väsentliga skyldigheter och förpliktelser som följer av avtalet eller gällande lag i Danmark och inte återställer det avtalsenliga tillståndet trots en skriftlig varning från den andra parten inom 7 dagar efter mottagandet av varningen. Varningen måste specificera brottet mot en skyldighet eller plikt och påpeka möjligheterna till uppsägning av goda skäl. Väsentliga avtalsförpliktelser är särskilt skyldigheter att upprätthålla konfidentialitet.</p>

          <p>För Matthias Kupperschmidt är en god orsak given om</p>
          <p>ansökan om insolvens- eller konkursförfarande mot avtalsparternas tillgångar har lämnats in</p>
          <ol>
            <li>alla andra culpabla brott mot väsentliga avtalsförpliktelser eller skyldigheter från avtalsparterna om de inte omedelbart har eliminerats trots en varning.</li>
          </ol>
          <p>Fram till dess att uppsägningen träder i kraft, behåller varje avtalsparter sin rätt till eventuella avgifter och provisioner som fortfarande kommer att uppstå. Fram till denna tidpunkt är avtalsparterna också skyldiga att uppfylla sina avtalsförpliktelser. Alla skyldigheter som sträcker sig utöver avtalstiden, särskilt de som rör dataskydd och konfidentialitet, förblir oförändrade av detta.</p>
          <p>Alla uppsägningar måste ske skriftligen eller via e-post.</p>

          <p>Avsnitt 6 Ersättning och förfallodatum</p>
          <p>Den överenskomna ersättningen förfaller vid slutet av konsultationen och/eller genomförandet av åtgärderna. Ett godkännande krävs inte.</p>
          <p>Matthias Kupperschmidt har rätt till skälig betalning redan före slutet av konsultationen och genomförandet av åtgärderna. I tveksamma fall baseras skäligheten på den redan tillhandahållna arbetstiden. Vid permanent konsultation har Matthias Kupperschmidt rätt att fakturera tjänsterna omedelbart efter att de har tillhandahållits. Ersättningen baseras på det erbjudande som gjorts av Matthias Kupperschmidt.</p>

          <p>Avsnitt 7 Pengatransaktioner, betalningsvillkor</p>
          <p>Ersättningen, eller fördelningen av försäljningen, sker alltid enligt den specifikation som anges i den individuella ordern.</p>
          <p>Om inget annat avtalats i den individuella ordern, görs avräkningen av Matthias Kupperschmidt månadsvis. Alla försäljningssiffror och avgifter är nettobelopp; moms visas separat.</p>
          <p>Matthias Kupperschmidt har rätt att kräva förskottsbetalningar på upp till 100% av ordervolymen; förskottsbetalningar förfaller vid undertecknandet av avtalet.</p>
          <p>Vid sen betalning har Matthias Kupperschmidt rätt att kräva dröjsmålsränta av avtalsparterna – samtidigt som rätten att göra anspråk på ytterligare skadestånd förbehålls – med ett belopp av fem procentenheter över den respektive basräntan. Dröjsmålsräntan är lägre om avtalsparterna bevisar en lägre skada.</p>
          <p>Avtalsparterna har endast en rätt till kvarhållande eller kvittning i den mån avtalsparternas anspråk är obestridda och har fastställts rättsligt.</p>


          <p>Avsnitt 8 Äganderätt och upphovsrättsskydd, nyttjanderätt</p>
          <p>Upphovsrätten och andra äganderätter till de tjänster som tillhandahålls av Matthias Kupperschmidt förblir hos Matthias Kupperschmidt.</p>
          <p>Matthias Kupperschmidt beviljar avtalsparterna icke-exklusiva, temporära och territoriellt obegränsade nyttjanderättigheter för utnyttjandet av den kompletta tjänsten som levererats enligt avtal (arbetets resultat).</p>
          <p>Avtalsparterna har dock inte rätt att sälja det levererade innehållet och informationen till tredje parter eller på annat sätt göra dem tillgängliga för tredje parter utanför deras avsedda syfte.</p>
          <p>Ändringar av de tjänster som tillhandahålls av Matthias Kupperschmidt och som ska utföras av avtalsparterna eller en tredje part som uppdragits av avtalsparterna är endast tillåtna med Matthias Kupperschmidts uttryckliga samtycke.</p>
          <p>Nyttjandet av nyttjanderättigheterna är endast tillåtet om avtalsparterna inte är i efterskott med en betalning vid den tidpunkten.</p>
          <p>Utifrån avtal med anställda, agenter och eventuella tredje parts rättighetsinnehavare säkerställer Matthias Kupperschmidt att avtalsparterna fritt kan utnyttja de avtalsenliga nyttjanderättigheterna.</p>

          <p>Avsnitt 9 Garanti och ansvar</p>
          <p>Matthias Kupperschmidt tillhandahåller sina tjänster inom ramen för sina tekniska möjligheter och åtar sig ingen garanti för oavbruten och/eller konstant felfri tillgänglighet av sina tjänster; i synnerhet inte för linjeavbrott och/eller anslutningsfel, hårdvaru- och/eller programvarufel eller för åtgärder från tredje part (t.ex. virus eller ”denial of service”-attacker). Avtalsparterna är överens om att förekomsten av sådana avbrott och/eller fel inte ger upphov till några ersättningskrav.</p>
          <p>Defekter är avvikelser från tjänstebeskrivningen som begränsar den avtalsenliga användningen. Eventuella defekter måste rapporteras till Matthias Kupperschmidt i detalj. Parterna ska samarbeta i defektanalys och felsökning. Rättelse utförs inom ramen för ansvar för defekter och innebär inga kostnader för avtalsparterna.</p>
          <p>Avtalsparterna ska ge Matthias Kupperschmidt en tillräcklig tid för rättelse. Om rättelsen inte lyckas ens inom en andra, tillräcklig frist (minst dock inom 15 arbetsdagar), har avtalsparterna rätt att kräva en minskning av betalningen eller ersättning för skada på grund av utebliven prestation om de har meddelat ett sådant steg vid fastställandet av fristen.</p>
          <p>Anspråk på defekter från avtalsparterna kan inte längre göras gällande om de har utfört ändringar av tjänsterna själva eller låtit sådana ändringar utföras av tredje part utan skriftligt samtycke i förväg, om inte avtalsparterna kan bevisa att defekterna inte beror på dessa ändringar eller att dessa ändringar inte hindrar defektanalysen och elimineringen eller gör det endast marginellt.</p>
          <p>Anspråk på defekter är i princip uteslutna om avtalsparterna använder tjänsterna under användningsvillkor som avviker från de som anges i avtalet. Det är avtalsparternas ansvar att bevisa att defekten inte orsakades av en sådan avvikelse.</p>
          <p>Anspråk är uteslutna för skador enligt den danska köplagen på grund av brister vid avtalets början som Matthias Kupperschmidt inte ansvarar för samt på grund av endast vårdslösa brister.</p>
          <p>Anspråk på defekter ska preskriberas ett år efter godkännande; i fråga om tjänster som inte är kapabla eller inte behöver godkännande, ett år efter leverans/övertagande eller efter meddelande om slutförande och/eller tillhandahållande av tjänsten.</p>
          <p>Matthias Kupperschmidt är inte ansvarig för tekniska fel vars orsaker inte ligger inom Matthias Kupperschmidts ansvarsområde eller för skador orsakade av force majeure. I undantagsfall kan det inträffa att enskilda data, tjänster eller andra tekniska eller faktiska aktiviteter inte kan registreras eller faktureras korrekt av Matthias Kupperschmidt. Eventuella anspråk baserade på detta – särskilt skadeståndsanspråk eller värdeersättningar – mot Matthias Kupperschmidt från avtalsparterna är uteslutna.</p>
          <p>Matthias Kupperschmidt är endast ansvarig för skador på grund av brott mot väsentliga avtalsförpliktelser eller på grund av frånvaron av garanterade egenskaper och egenskaper. Utöver detta är Matthias Kupperschmidt endast ansvarig om skadan orsakades på grund av grov vårdslöshet eller avsiktlig handling.</p>
          <p>Matthias Kupperschmidt är ansvarig vid en lätt vårdslös överträdelse av en väsentlig avtalsförpliktelse och, i fall av icke-avtalsmässig ansvarighet, endast i händelse av förlust av liv, fysisk skada och hälsoskada, vid brott mot garantier, väsentliga avtalsförpliktelser samt i enlighet med produktansvarslagen endast i den omfattning som är tillämplig för typisk skada som var förutsebar när avtalet ingicks. Skadan beräknas utifrån den genomsnittliga månadsvisa ordervolymen under de senaste sex månaderna. I synnerhet är ansvar för lätt vårdslös överträdelse av väsentliga avtalsförpliktelser vid förlust av vinst, indirekt skada och följdskada i princip uteslutet.</p>
          <p>Ansvar för följdskador, indirekta skador och/eller förlust av vinst är uteslutet om det inte rör sig om avsiktligt beteende. I termer av belopp är ansvaret begränsat till den förväntade normala skadan vid en förlust inom Danmark; dock maximalt till den totala ersättningen som ska betalas av avtalsparterna enligt den respektive ordern. Denna ansvarsbegränsning ska gälla på samma sätt för skador orsakade av grov vårdslöshet eller avsikt på anställda eller agenter hos Matthias Kupperschmidt.</p>
          <p>Om avtalsparterna har gjort ändringar i den avtalsenliga tjänsten eller dess miljö eller låtit sådana ändringar utföras av tredje part, upphör alla anspråk från avtalsparterna enligt ovanstående bestämmelser, om inte defekten inte har orsakats, helt eller delvis, av de ändringar som utförts av avtalsparterna och rättelsen inte försvåras av ändringarna.</p>
          <p>Dessa ansvarsbegränsningar ska gälla på motsvarande sätt för alla anställda och agenter hos Matthias Kupperschmidt. Matthias Kupperschmidts ansvar enligt produktansvarslagen förblir oförändrat.</p>
          <p>Alla anspråk på skador eller andra anspråk som skulle kunna riktas mot Matthias Kupperschmidt på grund av egenskaper och tillstånd eller defekter i tjänsten faller under preskription 6 (i ord: sex) månader efter slutet av det respektive avtalet.</p>
          <p>Avtalsparterna är ansvariga gentemot Matthias Kupperschmidt för innehåll, dokument, länkar och annat material (nedan kallade ”dokument”) som tillhandahålls av dem och gjorts tillgängliga för Matthias Kupperschmidt för genomförandet av samarbetet.</p>
          <p>Om det krävs ska avtalsparterna på egen bekostnad kontrollera om de tjänster som tillhandahålls av Matthias Kupperschmidt överensstämmer med relevanta lagbestämmelser. Matthias Kupperschmidt kommer endast att initiera en sådan extern juridisk granskning på uttrycklig skriftlig begäran från avtalsparterna; de tillhörande kostnaderna ska bäras av avtalsparterna.</p>
          <p>Om tredje part riktar anspråk mot Matthias Kupperschmidt på grund av en överträdelse av upphovsrätt och/eller brott mot relevanta lagbestämmelser som inträffat inom avtalsparternas ansvarsområde, ska avtalsparterna göra allt i deras makt för att försvara Matthias Kupperschmidt mot de påstådda anspråken eller rättigheterna och eliminera den påstådda överträdelsen.</p>

          <p>Avsnitt 10 Tillåtlighet av webbplatser</p>
          <p>morefire kontrollerar inte om innehållet på kundens webbplatser inkräktar på tredje parts rättigheter eller är upprättade i enlighet med riktlinjerna för de enskilda sökmotoroperatörerna. Kunden är ensam ansvarig för eventuella påföljder från tredje part.</p>
          <p>Kunden är också ansvarig för den juridiska tillåtligheten av innehållet på sina webbplatser samt för informationen som tillhandahålls av honom, inklusive söktermer eller nyckelord; samma gäller för skyddet av tredje parts rättigheter, särskilt när det gäller upphovsrätt, konkurrenslagstiftning och straffrätt.</p>

          <p>Avsnitt 11 Tjänsteavtal</p>
          <p>Anställda som används inom ramen för tjänsteavtal får inte rekryteras av kunden inom en period av 24 månader efter avtalets ingående. Om en sådan rekrytering sker inom denna period har entreprenören rätt till en engångsbetalning på €75,000.</p>

          <p>Avsnitt 12 Dataskydd</p>
          <p>Utan ett ytterligare samtyckesförklaring samlas, behandlas och används personuppgifter – om inget annat anges – endast för att tillhandahålla tjänsterna, användningen av tjänsterna och, om tillämpligt, för fakturering.</p>
          <p>Avtalsparterna är skyldiga att följa alla tillämpliga bestämmelser om dataskydd, särskilt bestämmelserna i den danska dataskyddslagen (DDPA) och EU:s allmänna dataskyddsförordning (GDPR).</p>
          <p>Avtalsparterna garanterar att använda data de kan ha samlat in exklusivt för det angivna syftet samt för marknadsundersöknings- och reklamändamål inom företaget, i den mån det krävs för genomförandet av de ingångna transaktionerna och underhållet av den resulterande användarrelationen och om det är tillåtet enligt lag och önskas av användaren. Vidareförsäljning av data till tredje part av avtalsparterna är utesluten, och åtal kommer att väckas.</p>

          <p>Avsnitt 13 Konfidentialitet och sekretess</p>
          <p>Avtalsparterna är skyldiga att använda de dokument, kunskaper och erfarenheter som erhållits från Matthias Kupperschmidt enbart för syftena med detta avtal. Dessutom är avtalsparterna överens om konfidentialitet gentemot tredje parter avseende innehållet i de avtal som ingåtts samt all kunskap som erhållits under genomförandet. Avtalsparterna är skyldiga att rådgöra med Matthias Kupperschmidt om några tvivel uppstår huruvida någon information i ett specifikt enskilt fall ska hanteras konfidentiellt.</p>
          <p>Vid eventuell underentreprenad ska avtalsparterna förplikta den respektive underleverantören till skyldigheter i enlighet med denna bestämmelse.</p>
          <p>Avtalsparterna ska säkerställa efterlevnad av denna skyldighet att upprätthålla konfidentialitet avseende sina anställda och eventuella tredje parter som är involverade i avtalets genomförande som har tillgång till de dokument och föremål som anges i första stycket.</p>


          <p>Avsnitt 14 Ändringar</p>
          <p>Matthias Kupperschmidt ska tillhandahålla sina tjänster i enlighet med respektive produktbeskrivningar. Matthias Kupperschmidt förbehåller sig rätten att modifiera, utöka eller anpassa tjänsterna, helt eller delvis, eller att modifiera, utöka eller anpassa typen och innehållet av enskilda produkter och tjänster som erbjuds inom ramen för tillhandahållandet av tjänsterna när som helst och upprepade gånger för att förbättra erbjudandet, särskilt dess funktioner, design och tekniska utveckling. Avtalsparterna ska informeras om större ändringar av produkterna via e-post i god tid. Den nya versionen av produktbeskrivningarna ska bifogas. Om avtalsparterna inte godkänner ändringen har de rätt att säga upp avtalet med Matthias Kupperschmidt med omedelbar verkan. Om avtalsparterna inte invänder mot ändringen inom två veckor anses de meddelade ändringarna som de nya villkoren.</p>
          <p>Dessutom har Matthias Kupperschmidt rätt att avbryta enskilda funktioner som erbjuds som en del av tjänsterna – särskilt vid ändrade lagstadgade krav, långvariga förluster, tekniska svårigheter eller vid missbruk av kunder, avtalsparter eller tredje parter.</p>
          <p>Matthias Kupperschmidt förbehåller sig också uttryckligen rätten att göra prisändringar, varom Matthias Kupperschmidt ska informera avtalsparterna inom rimlig tid.</p>
          <p>Matthias Kupperschmidt förbehåller sig också rätten att ändra eller komplettera dessa allmänna villkor, särskilt vid införandet av nya tjänster eller när ändringar krävs på grund av lagstadgade bestämmelser. Matthias Kupperschmidt ska meddela avtalsparterna om ändringar eller tillägg till allmänna villkor. Om avtalsparterna inte godkänner ändringarna kan de invända mot dem inom två veckor efter att de har meddelats skriftligen.</p>
          <p>Om avtalsparterna invänder mot ändringar av tjänsterna, dessa allmänna villkor eller de särskilda villkoren för de enskilda produkterna, har Matthias Kupperschmidt rätt att utan förvarning säga upp avtal som ingåtts enligt dessa allmänna villkor. Om avtalsparterna inte invänder anses deras samtycke ha getts vid utgången av de ovannämnda perioderna. Matthias Kupperschmidt ska i meddelandet om ändringen av allmänna villkor påpeka periodens längd och dess betydelse vid utgången av perioden.</p>

          <p>Avsnitt 15 Slutbestämmelser</p>
          <p>Att skicka meddelanden till Matthias Kupperschmidt via e-post uppfyller kravet på skriftlig form endast om det uttryckligen föreskrivs i dessa användarvillkor och om Matthias Kupperschmidt uttryckligen har godkänt det i förväg.</p>
          <p>En överlåtelse av avtalsrättigheter och skyldigheter av avtalsparterna till tredje part är tillåten endast med föregående skriftligt samtycke från Matthias Kupperschmidt.</p>
          <p>Avtalsparterna har endast en rätt till kvarhållande i fråga om obestridda eller rättsligt fastställda anspråk.</p>
          <p>Det finns inga säkerhetsavtal. Alla avtal mellan parterna måste vara skriftliga. Detta gäller även för upphävandet av kravet på skriftlig form.</p>
          <p>Avtalsparterna ska i god tid informera Matthias Kupperschmidt om pågående insolvensförfaranden för att säkerställa fullgörandet av avtalsenliga tjänster och skyldigheter eller för att möjliggöra en ömsesidig uppsägning av avtalet.</p>
          <p>Om enskilda bestämmelser i de allmänna villkoren är ogiltiga i sin helhet eller delvis eller om de innehåller en lucka, påverkas inte giltigheten av de återstående bestämmelserna eller delar av sådana bestämmelser av detta. I sådant fall är parterna skyldiga att delta i utformningen av sådana nya bestämmelser, genom vilka ett kommersiellt resultat uppnås med rättslig effekt som ligger så nära den ogiltiga eller ofullständiga bestämmelsen som möjligt, samtidigt som ömsesidiga intressen beaktas.</p>
          <p>Platsen för prestation för båda parter är Matthias Kupperschmidts verksamhetsort.</p>
          <p>Den danska lagen gäller uteslutande med undantag för Förenta nationernas konvention om avtal för internationell försäljning av varor</p>
          <p>(CISG) och hänvisningar till utländsk lag.</p>
          <p>Om kunden är en näringsidkare, juridisk person under offentlig rätt eller en särskild fond under offentlig rätt eller om han inte har något registrerat kontor i Danmark, är den exklusiva jurisdiktionen Köpenhamn, Danmark. Matthias Kupperschmidt har också rätt att väcka talan vid avtalsparternas verksamhetsort.</p>

          <p>Avsnitt 13 Tvistlösning</p>
          <p>Europeiska kommissionen har upprättat en plattform för online tvistlösning. Den kan nås på: http://ec.europa.eu/consumers/odr/.</p>
          <p>Konsumenter kan använda plattformen för tvistlösning. Matthias Kupperschmidt är inte villig eller skyldig att delta i tvistlösningsförfaranden inför en konsumentnämnd om det inte finns en lagstadgad skyldighet att delta.</p>
          <p>Köpenhamn, 01 februari 2019</p>




        </MainContent>
      </React.Fragment>

    </Layout>
  );
};
export default LegalNotice;
